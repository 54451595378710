import {
  Button,
  Card,
  Divider,
  Paper,
  Stack,
  TextInput,
  Title
} from "@mantine/core"
import AppText from "../../../../shared/components/AppText"
import { IconMail } from "@tabler/icons"
import { useStateContext } from "../AuthContext"
import { useForm, zodResolver } from "@mantine/form"
import { z } from "zod"
import { useMutation } from "react-query"

import { Auth } from "aws-amplify"
import Alert from "../../../../shared/components/Alert"

const forgotPasswordSchema = z.object({
  username: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Please insert an email address" })
})

export default function ForgotPasswordComponent() {
  const { goToState } = useStateContext("ForgotPassword")

  const {
    mutate: forgotPassword,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: { username: string }) => Auth.forgotPassword(args.username),
    {
      onSuccess: (data, variables) =>
        goToState("ResetPassword", {
          username: variables.username
        })
    }
  )

  const forgotPasswordForm = useForm({
    initialValues: { username: "" },
    validate: zodResolver(forgotPasswordSchema)
  })

  const handleForgotPassword = () => {
    const valRes = forgotPasswordForm.validate()
    if (!valRes.hasErrors)
      forgotPassword({
        username: forgotPasswordForm.values.username
      })
  }

  return (
    <Card mt={"10%"} mx="auto" sx={{ maxWidth: 500 }}>
      <Stack spacing="xl" justify="space-between" sx={{ height: "100%" }}>
        <Title order={1}>Forgot password</Title>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleForgotPassword()
          }}
        >
          <Stack spacing="sm">
            <Paper p="xl">
              <TextInput
                autoFocus
                icon={<IconMail size={20} />}
                placeholder="Your email"
                type="email"
                {...forgotPasswordForm.getInputProps("username")}
              />
              {isError && (
                <Alert type={"error"} message={(error as Error).message} />
              )}
            </Paper>
            <Stack spacing="xl">
              <Button
                loading={isLoading}
                type={"submit"}
                size="md"
                color="button/primary"
              >
                <AppText type={"button-large"} color={"text/default"}>
                  Reset my password
                </AppText>
              </Button>
              <Divider />
              <Button
                loading={isLoading}
                size="md"
                color="button/secondary"
                onClick={() => goToState("Login", undefined)}
              >
                <AppText type={"button-large"} color={"text/default"}>
                  Back to login
                </AppText>
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Card>
  )
}
