import {
  Button,
  PasswordInput,
  Stack,
  TextInput,
  Title,
  Paper,
  Card,
  Divider
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useStateContext } from "../AuthContext"
import { IconKey } from "@tabler/icons"
import Alert from "../../../../shared/components/Alert"
import AppText from "../../../../shared/components/AppText"
import { useMutation } from "react-query"

import { Auth } from "aws-amplify"
import { resetPasswordSchema } from "../../../../shared/formSchemas"

export default function ResetPasswordComponent() {
  const { goToState, data } = useStateContext("ResetPassword")

  const {
    mutate: resetPassword,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: { code: string; password: string }) =>
      Auth.forgotPasswordSubmit(data.username, args.code, args.password),
    {
      onSuccess: () => goToState("Login", undefined)
    }
  )

  const resetPasswordForm = useForm({
    initialValues: {
      code: "",
      password: "",
      confirmPassword: ""
    },
    validate: zodResolver(resetPasswordSchema)
  })

  const handleResetPassword = () => {
    const valRes = resetPasswordForm.validate()
    if (!valRes.hasErrors)
      resetPassword({
        code: resetPasswordForm.values.code,
        password: resetPasswordForm.values.password
      })
  }

  return (
    <Card mt={"10%"} mx="auto" sx={{ maxWidth: 500 }}>
      <Stack justify="space-between" sx={{ height: "100%" }}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleResetPassword()
          }}
        >
          <Stack>
            <Title order={1} mb="xl">
              Reset your password
            </Title>
            <Paper p="xl">
              <Alert
                type={"info"}
                message={
                  "A verification code has been sent to your email address"
                }
              />
              <TextInput
                autoFocus
                pb="xs"
                placeholder="Verification code"
                {...resetPasswordForm.getInputProps("code")}
              />
              <PasswordInput
                pb="xs"
                icon={<IconKey size={20} />}
                placeholder="New password"
                {...resetPasswordForm.getInputProps("password")}
              />
              <PasswordInput
                pb="xs"
                icon={<IconKey size={20} />}
                placeholder="Confirm new password"
                {...resetPasswordForm.getInputProps("confirmPassword")}
              />
              {isError && (
                <Alert type={"error"} message={(error as Error).message} />
              )}
            </Paper>
            <Stack spacing="xl">
              <Button
                disabled={!resetPasswordForm.isValid()}
                size="md"
                type={"submit"}
                color="button/primary"
              >
                <AppText type={"button-large"} color={"text/default"}>
                  Reset password
                </AppText>
              </Button>
              <Divider />
              <Button
                loading={isLoading}
                size="md"
                color="button/secondary"
                onClick={() => goToState("Login", undefined)}
              >
                <AppText type={"button-large"} color={"text/default"}>
                  Back to login
                </AppText>
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Card>
  )
}
