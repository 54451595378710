import React, { useState } from "react"
import { AppRouter } from "./modules"
import { QueryClient, QueryClientProvider } from "react-query"
import { MantineProvider } from "@mantine/core"
import { platformTheme } from "./shared/theme"
import { BrowserRouter } from "react-router-dom"
import { ReactQueryDevtools } from "react-query/devtools"
import Authenticated from "./modules/login/pages"
import { NotificationsProvider } from "@mantine/notifications"

function App() {
  const [client] = useState(
    new QueryClient({
      defaultOptions: { queries: { refetchOnWindowFocus: true } }
    })
  )
  return (
    <QueryClientProvider client={client}>
      <MantineProvider withGlobalStyles withNormalizeCSS theme={platformTheme}>
        <NotificationsProvider>
          <BrowserRouter>
            <Authenticated>
              <AppRouter />
            </Authenticated>
          </BrowserRouter>
        </NotificationsProvider>
      </MantineProvider>
      {false && <ReactQueryDevtools />}
    </QueryClientProvider>
  )
}

export default App
