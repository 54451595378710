import {
  AuthContextProvider,
  useAuthContext
} from "../../components/AuthContext"
import { AppShell } from "@mantine/core"
import LoginComponent from "../../components/Login"
import ForgotPasswordComponent from "../../components/ForgotPassword"
import ResetPasswordComponent from "../../components/ResetPassword"
import ChangePasswordComponent from "../../components/ChangePassword"

function AuthComponent() {
  const { currentState } = useAuthContext()
  if (currentState === "Login") return <LoginComponent />
  if (currentState === "ForgotPassword") return <ForgotPasswordComponent />
  if (currentState === "ResetPassword") return <ResetPasswordComponent />
  if (currentState === "ChangePassword") return <ChangePasswordComponent />

  return <>Unhandled {currentState}</>
}

export default function AuthPage() {
  return (
    <AuthContextProvider>
      <AppShell
        padding={"xl"}
        sx={(th) => ({
          backgroundColor: th.colors["bg/primary-l1"][0]
        })}
      >
        <AuthComponent />
      </AppShell>
    </AuthContextProvider>
  )
}
