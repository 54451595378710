import React, { Suspense } from "react"
import { Route, Routes } from "react-router-dom"

const LazyDashboardModule = React.lazy(() => import("./dashboard"))
const LazySubmissionDetailsModule = React.lazy(
  () => import("./dashboard/pages/Submissions/pages/Details")
)

export function AppRouter() {
  return (
    <>
      <Routes>
        <Route
          path={"*"}
          element={
            <Suspense>
              <LazyDashboardModule />
            </Suspense>
          }
        />
        <Route
          path={"submissions/:submissionId/*"}
          element={
            <Suspense>
              <LazySubmissionDetailsModule />
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}
