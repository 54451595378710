import { createContext, ReactNode, useContext, useState } from "react"

type STATES = {
  Login: undefined
  VerifyAccount: { username: string }
  ForgotPassword: undefined
  ResetPassword: { username: string }
  ChangePassword: { username: string; oldPassword: string }
}

export type AuthContextType<State extends keyof STATES> = {
  currentState: State
  data: STATES[State]
  goToState: <K extends keyof STATES>(state: K, data: STATES[K]) => void
}

const AuthContext = createContext<AuthContextType<keyof STATES>>({
  currentState: "Login",
  data: undefined,
  goToState: () => true
})

export function useAuthContext() {
  return useContext(AuthContext)
}

export function useStateContext<State extends keyof STATES>(
  state: State
): AuthContextType<State> {
  return useContext(AuthContext) as AuthContextType<State>
}

export function AuthContextProvider(props: { children: ReactNode }) {
  const [state, setState] = useState<{
    currentState: keyof STATES
    data: STATES[keyof STATES]
  }>({ currentState: "Login", data: undefined })

  return (
    <AuthContext.Provider
      value={{
        currentState: state.currentState,
        data: state.data,
        goToState: (stateName, data) =>
          setState({ currentState: stateName, data })
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
