import { useAuthContext } from "../AuthContext"
import { useForm, zodResolver } from "@mantine/form"
import { IconMail, IconKey } from "@tabler/icons"

import { Auth } from "aws-amplify"

import {
  Anchor,
  Button,
  PasswordInput,
  Stack,
  TextInput,
  Title,
  Paper,
  Card
} from "@mantine/core"
import { z } from "zod"
import AppText from "../../../../shared/components/AppText"
import { useMutation, useQueryClient } from "react-query"
import Alert from "../../../../shared/components/Alert"

const loginSchema = z.object({
  username: z
    .string()
    .email({ message: "Invalid email address" })
    .min(1, { message: "Please insert an email address" }),
  password: z.string().min(1, { message: "Please enter a password" })
})

export default function LoginComponent() {
  const queryClient = useQueryClient()
  const { goToState } = useAuthContext()

  const loginForm = useForm({
    initialValues: { username: "", password: "" },
    validate: zodResolver(loginSchema)
  })

  const {
    mutate: signIn,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: { username: string; password: string }) =>
      Auth.signIn(args.username, args.password),
    {
      onError: (error: Error, variables) => {
        if (error.name === "UserNotConfirmedException") {
          goToState("VerifyAccount", { username: variables.username })
        }
      },
      onSuccess: (data, variables) => {
        if (
          ["PASSWORD_VERIFIER", "NEW_PASSWORD_REQUIRED"].includes(
            data.challengeName
          )
        ) {
          goToState("ChangePassword", {
            username: variables.username,
            oldPassword: variables.password
          })
        } else {
          queryClient.invalidateQueries(["session"]).then(() => undefined)
        }
      }
    }
  )

  const handleLogin = () => {
    const valRes = loginForm.validate()
    if (valRes.hasErrors) return

    signIn({
      username: loginForm.values.username,
      password: loginForm.values.password
    })
  }

  return (
    <Card mt={"10%"} mx="auto" sx={{ maxWidth: 500 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleLogin()
        }}
      >
        <Stack>
          <Title order={1} mb="xl">
            Log-in
          </Title>
          <Paper p="xl">
            <TextInput
              autoFocus
              icon={<IconMail size={20} />}
              pb="xs"
              placeholder={"Email"}
              {...loginForm.getInputProps("username")}
            />
            <PasswordInput
              icon={<IconKey size={20} />}
              pb="xs"
              placeholder={"Password"}
              {...loginForm.getInputProps("password")}
            />
            <Anchor
              onClick={() => goToState("ForgotPassword", undefined)}
              sx={(th) => th.other.text["button-large"]}
            >
              Forgot password?
            </Anchor>
            {isError && (
              <Alert type={"error"} message={(error as Error).message} />
            )}
          </Paper>
          <Button
            loading={isLoading}
            size="md"
            type={"submit"}
            color="button/primary"
          >
            <AppText type={"button-large"} color={"text/default"}>
              Log in
            </AppText>
          </Button>
        </Stack>
      </form>
    </Card>
  )
}
