import { Button, PasswordInput, Stack, Title, Paper, Card } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useMutation } from "react-query"
import { Auth } from "aws-amplify"
import { useStateContext } from "../AuthContext"
import { IconKey } from "@tabler/icons"
import Alert from "../../../../shared/components/Alert"
import AppText from "../../../../shared/components/AppText"
import { changePasswordSchema } from "../../../../shared/formSchemas"

export default function ChangePasswordComponent() {
  const { goToState, data } = useStateContext("ChangePassword")

  const {
    mutate: resetPassword,
    isLoading,
    error,
    isError
  } = useMutation(
    (args: { newPassword: string; confirmPassword: string }) =>
      Auth.signIn(data.username, data.oldPassword).then((user) => {
        Auth.completeNewPassword(user, args.newPassword).then(() =>
          goToState("Login", undefined)
        )
      }),
    {
      onSuccess: () => goToState("Login", undefined)
    }
  )

  const changePasswordForm = useForm({
    initialValues: {
      newPassword: "",
      confirmPassword: ""
    },
    validate: zodResolver(changePasswordSchema)
  })

  const handleChangePassword = () => {
    const valRes = changePasswordForm.validate()
    if (valRes.hasErrors) return
    else {
      resetPassword({
        newPassword: changePasswordForm.values.newPassword,
        confirmPassword: changePasswordForm.values.confirmPassword
      })
    }
  }

  return (
    <Card mt={"10%"} mx="auto" sx={{ maxWidth: 500 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleChangePassword()
        }}
      >
        <Stack>
          <Title order={1} mb="xl">
            Change password
          </Title>
          <Paper p="xl">
            <PasswordInput
              pb="xs"
              icon={<IconKey size={20} />}
              placeholder="New password"
              {...changePasswordForm.getInputProps("newPassword")}
            />
            <PasswordInput
              pb="xs"
              icon={<IconKey size={20} />}
              placeholder="Confirm new password"
              {...changePasswordForm.getInputProps("confirmPassword")}
            />
            {isError && (
              <Alert type={"error"} message={(error as Error).message} />
            )}
          </Paper>
          <Button
            disabled={!changePasswordForm.isValid()}
            loading={isLoading}
            size="md"
            type={"submit"}
            color="button/primary"
          >
            <AppText type={"button-large"} color={"text/default"}>
              Change password
            </AppText>
          </Button>
        </Stack>
      </form>
    </Card>
  )
}
