import { ReactNode } from "react"

import { Amplify, Auth } from "aws-amplify"

import AuthPage from "./Auth"
import { Center, Group, Loader } from "@mantine/core"
import LogoInlineWithText from "../../../shared/logo"
import { env } from "../../../env/env"
import { useQuery } from "react-query"

Amplify.configure({
  aws_cognito_region: env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: env.REACT_APP_USER_POOLS_ID,
  aws_user_pools_web_client_id: env.REACT_APP_USER_POOLS_WEB_CLIENT_ID
})

export default function Authenticated(props: { children: ReactNode }) {
  const { data: currentSession, isLoading } = useQuery(
    ["session"],
    () => Auth.currentSession().catch(() => undefined),
    {
      retry: false,
      staleTime: 0,
      cacheTime: 0
    }
  )

  if (isLoading)
    return (
      <Center
        sx={(th) => ({
          width: "100%",
          height: "100vh",
          backgroundColor: th.colors["bg/primary-l1"][0]
        })}
      >
        <Group align={"center"}>
          <LogoInlineWithText dark />
          <Loader size={"sm"} />
        </Group>
      </Center>
    )
  if (!currentSession) return <AuthPage />
  if (currentSession) return <>{props.children}</>
  return <>Never</>
}
