import { CSSObject } from "@mantine/core"

export const fonts = {
  inter: "'Inter', sans-serif",
  serif: "'DM Serif Display', serif"
}

function defineTypography<K extends Record<string, CSSObject>>(t: K) {
  return t
}

export const platformTypography = defineTypography({
  "heading-xlarge": {
    fontFamily: fonts.serif,
    fontSize: 80,
    lineHeight: "88px",
    fontWeight: 400
  },
  "body-large": {
    fontFamily: fonts.inter,
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400
  },
  "body-medium": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400
  },
  "body-small": {
    fontFamily: fonts.inter,
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400
  },
  "button-large": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400
  },
  "button-medium": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "16px",
    fontWeight: 400
  },
  "label-dimmed": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "24px",
    fontWeight: 400
  },
  "label-large": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "18px",
    fontWeight: 400
  },
  "label-medium": {
    fontFamily: fonts.inter,
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 500
  },
  "label-medium-light": {
    fontFamily: fonts.inter,
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400
  },
  "label-large-underline": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 400,
    textDecoration: "underline"
  },
  "label-large-strong": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 600
  },
  "title-large": {
    fontFamily: fonts.inter,
    fontSize: 20,
    lineHeight: "26px",
    fontWeight: 400
  },
  "title-medium": {
    fontFamily: fonts.inter,
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 500
  },
  "title-small": {
    fontFamily: fonts.inter,
    fontSize: 14,
    lineHeight: "20px",
    fontWeight: 500
  },
  "headline-2": {
    fontFamily: fonts.inter,
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: 400
  },
  "headline-3": {
    fontFamily: fonts.serif,
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: 400
  },
  stats: {
    fontFamily: fonts.inter,
    fontSize: 28,
    lineHeight: "32px",
    fontWeight: 300
  },
  "graph-label": {
    fontFamily: fonts.inter,
    fontSize: 10,
    lineHeight: "24px",
    fontWeight: 400
  }
})
export type CustomTypographyType = keyof typeof platformTypography
