import { CSSObject, MantineThemeOverride } from "@mantine/core"
import { platformColors } from "./colors"
import { CustomTypographyType, fonts, platformTypography } from "./typography"
import { CustomShadowType, platformShadows } from "./shadow"

declare module "@mantine/core" {
  export interface MantineThemeOther {
    text: Record<CustomTypographyType, CSSObject>
    shadow: Record<CustomShadowType, string>
  }
}

export const platformTheme: MantineThemeOverride = {
  colorScheme: "light",
  defaultRadius: "sm",
  colors: platformColors,
  fontFamily: fonts.inter,
  primaryColor: "text/highlight",
  radius: {
    xs: 4,
    sm: 6,
    md: 8,
    lg: 12,
    xl: 16
  },
  headings: {
    fontFamily: fonts.serif,
    sizes: {
      h1: {
        fontSize: 48,
        lineHeight: "52px",
        fontWeight: 400
      }
    }
  },
  other: {
    text: platformTypography,
    shadow: platformShadows
  },
  components: {
    Card: {
      defaultProps: {
        radius: "md"
      },
      styles: (th) => ({
        root: {
          backgroundColor: th.colors["bg/primary-l2"][0]
        }
      })
    },
    RadioGroup: {
      styles: {
        root: {
          "& > .mantine-Group-root > * ": {
            flexGrow: 4
          }
        }
      }
    },
    Radio: {
      defaultProps: {
        color: "text/highlight"
      },
      styles: (th) => ({
        root: {
          "&.checked label": {
            color: th.colors["text/default"][0]
          }
        },
        body: {
          borderRadius: th.radius.md,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: th.colors["border/input"][0],
          padding: 12,
          display: "flex",
          alignItems: "center"
        },
        icon: {
          color: th.fn.primaryColor(),
          borderWidth: 2,
          borderColor: th.white,
          borderStyle: "solid",
          borderRadius: 100,
          width: 12,
          height: 12,
          top: "calc(50% - 6px)",
          left: "calc(50% - 6px)"
        },
        labelWrapper: {
          height: 16,
          display: "flex",
          alignItems: "center",
          width: "100%"
        },
        radio: {
          height: 16,
          width: 16,
          cursor: "pointer"
        },
        label: {
          paddingLeft: 8,
          display: "block",
          color: th.colors["text/dimmed"][0],
          cursor: "pointer"
        },
        error: {
          borderColor: th.colors["border/alert"][0]
        }
      })
    },
    CheckboxGroup: {
      styles: {
        root: {
          "& > .mantine-Group-root > * ": {
            flexGrow: 4
          }
        },
        body: {
          justifyContent: "space-evenly"
        }
      }
    },
    Checkbox: {
      defaultProps: {
        color: "text/highlight"
      },
      styles: (th) => ({
        root: {
          "&.checked label": {
            color: th.colors["text/default"][0]
          }
        },
        body: {
          borderRadius: th.radius.md,
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: th.colors["border/input"][0],
          padding: 12,
          display: "flex",
          alignItems: "center"
        },
        input: {
          height: 16,
          width: 16,
          cursor: "pointer",
          borderRadius: 2
        },
        inner: {
          height: 16,
          width: 16
        },
        labelWrapper: {
          height: 16,
          display: "flex",
          alignItems: "center",
          width: "100%"
        },
        label: {
          paddingLeft: 8,
          display: "block",
          color: th.colors["text/dimmed"][0],
          cursor: "pointer"
        },
        error: {
          borderColor: th.colors["border/alert"][0]
        }
      })
    },
    ChipGroup: {
      defaultProps: {
        spacing: 6
      }
    },
    Chip: {
      defaultProps: {
        color: "bg/tabs/default-active",
        variant: "filled"
      },
      styles: (th) => ({
        label: {
          color: th.colors["text/half-dimmed"][0]
        }
      })
    },
    Container: {
      defaultProps: {
        p: 0,
        sizes: {
          xs: 128,
          sm: 256,
          md: 512,
          lg: 768,
          xl: 1366
        } as Record<"xs" | "sm" | "md" | "lg" | "xl", number>
      }
    },
    InputWrapper: {
      styles: (th) => ({
        error: {
          color: th.colors["text/alert"][0]
        },
        "[role=radiogroup]": {
          paddingTop: 0
        }
      })
    },
    Input: {
      styles: (th) => ({
        input: {
          ...th.other.text["button-large"],
          borderColor: th.colors["border/input"][0],
          "::placeholder": {
            ...th.other.text["button-large"],
            color: th.colors["text/dimmed"][0]
          }
        },
        invalid: {
          borderColor: th.colors["border/alert"][0],
          color: th.colors["text/alert"][0],
          "::placeholder": {
            color: th.colors["text/alert"][0]
          }
        },
        icon: {
          color: th.colors["text/disabled"][0]
        }
      })
    },
    Textarea: {
      defaultProps: {
        size: "md"
      }
    },
    TextInput: {
      defaultProps: {
        size: "md"
      }
    },
    TimeInput: {
      defaultProps: {
        size: "md"
      }
    },
    NumberInput: {
      defaultProps: {
        size: "md"
      },
      styles: (th) => ({
        input: {
          ...th.other.text["button-large"],
          color: th.colors["text/half-dimmed"][0]
        }
      })
    },
    DatePicker: {
      defaultProps: {
        size: "md"
      }
    },
    Select: {
      styles: (th) => ({
        input: {
          ...th.other.text["button-large"],
          color: th.colors["text/half-dimmed"][0]
        },
        item: {
          ...th.other.text["button-large"],
          color: th.colors["text/half-dimmed"][0]
        }
      })
    },
    PasswordInput: {
      defaultProps: {
        size: "md"
      },
      styles: (th) => ({
        innerInput: {
          "::placeholder": {
            ...th.other.text["button-large"],
            color: th.colors["text/dimmed"][0]
          }
        },
        invalid: {
          "::placeholder": {
            color: th.colors["text/alert"][0]
          }
        }
      })
    },
    Paper: {
      defaultProps: {
        radius: "lg",
        p: 8
      },
      styles: (th) => ({
        root: {
          backgroundColor: th.colors["bg/primary-l3"][0]
        }
      })
    },
    HoverCard: {
      styles: (th) => ({
        dropdown: {
          ...th.other.text["body-medium"],
          backgroundColor: th.colors["bg/inverted-l1"][0],
          color: th.colors["text/inverted-half-dimmed"][0]
        }
      })
    },
    Tabs: {
      styles: (th) => ({
        root: {
          ".dotted-tab": {
            borderRadius: 6,
            "&[data-active=true]": {
              color: th.colors["text/default"][0],
              boxShadow: th.other.shadow["primitive/shadow-l1"]
            },
            "&:not([data-active])": {
              color: th.colors["text/dimmed"][0],
              ".mantine-Tabs-tabRightSection svg": {
                fill: th.colors["text/disabled"][0],
                stroke: th.colors["text/disabled"][0]
              }
            }
          },
          ".mantine-Tabs-tabsList[aria-orientation=horizontal]": {
            ".mantine-Tabs-tab": {
              paddingTop: th.spacing.xs - 3,
              paddingBottom: th.spacing.xs - 3,
              backgroundColor: th.white,
              "&[data-active], &:hover, &[data-active]:hover": {
                ...th.other.text["label-large"],
                backgroundColor: th.colors["bg/tabs/default-active"][0],
                color: th.colors["text/default"][0]
              }
            }
          }
        }
      })
    },
    Switch: {
      styles: (th) => ({
        input: {
          "&:checked + .mantine-Switch-track": {
            backgroundColor: "transparent"
          },
          "&:checked +*> .mantine-Switch-thumb": {
            backgroundColor: th.fn.primaryColor(),
            borderColor: th.fn.primaryColor(),
            left: "calc(100% - 11px - 2px)"
          }
        },
        thumb: {
          width: 10,
          height: 10,
          backgroundColor: th.colors["border/card"][0],
          borderColor: th.colors["border/card"][0]
        },
        track: {
          borderWidth: 2,
          minWidth: 30,
          backgroundColor: "transparent",
          cursor: "pointer"
        },
        trackLabel: {
          minWidth: 10
        }
      })
    },
    NavLink: {
      styles: (th) => ({
        root: {
          padding: th.spacing.md,
          paddingBottom: th.spacing.md - 2,
          background: "transparent",
          ...th.other.text["label-large"],
          color: th.colors["text/inverted-half-dimmed"][0],
          borderBottomStyle: "solid",
          borderBottomWidth: 2,
          borderBottomColor: th.colors["border/inverted-dimmed"][0],
          "&[data-active]": {
            borderColor: th.colors["text/highlight"][9],
            borderStyle: "solid",
            borderRadius: 6,
            padding: th.spacing.md - 2,
            borderWidth: 2,
            background: "rgba(26, 33, 50, 0.65)",
            ...th.other.text["label-large"],
            color: th.colors["text/inverted-half-dimmed"][0]
          },
          ":hover": {
            background: "rgba(26, 33, 50, 0.65)"
          },
          "&[data-active]:hover": {
            background: "rgba(26, 33, 50, 0.65)"
          }
        }
      })
    },
    RichTextEditor: {
      styles: (th) => ({
        root: {
          borderColor: th.colors["border/input"][0]
        },
        content: {
          ...th.other.text["body-medium"]
        },
        toolbar: {
          borderColor: th.colors["border/input"][0],
          button: {
            "&[data-active]": {
              backgroundColor: th.colors["chip/blue-active"][0],
              color: th.colors["text/highlight"][9]
            }
          }
        }
      })
    },
    Table: {
      styles: (th) => ({
        root: {
          borderCollapse: "separate",
          borderSpacing: "0 4px",
          "thead tr th": {
            ...th.other.text["label-large"],
            color: th.colors["text/dimmed"][0],
            border: "none",
            paddingTop: 0
          },
          "tbody tr td": {
            ...th.other.text["body-medium"],
            backgroundColor: th.white,
            color: th.colors["text/default"][0]
          },
          "tfoot tr td": {
            ...th.other.text["title-small"],
            backgroundColor: th.colors["button/secondary"][0],
            paddingTop: th.spacing.sm,
            paddingBottom: th.spacing.sm,
            paddingRight: th.spacing.md,
            paddingLeft: th.spacing.md
          },
          "tbody tr td, tfoot tr td": {
            border: "none",
            "&:first-of-type": {
              borderTopLeftRadius: th.radius.md,
              borderBottomLeftRadius: th.radius.md
            },
            "&:last-of-type": {
              borderTopRightRadius: th.radius.md,
              borderBottomRightRadius: th.radius.md
            }
          }
        }
      })
    }
  }
}
