import { z } from "zod"
export const passwordRequirements = [
  { re: /[0-9]/, label: "Password must contain at least one number" },
  { re: /[a-z]/, label: "Password must contain at least one lowercase letter" },
  {
    re: /[A-Z]/,
    label: "Password must contain at least one  uppercase letter"
  },
  {
    re: /[$&+,:;=?@#|'<>.^*()%!-]/,
    label: "Password must contain at least one special symbol"
  }
]

export const resetPasswordSchema = z
  .object({
    code: z
      .string()
      .min(6, { message: "Verification code is not properly formatted" }),
    password: z.string().superRefine((val, ctx) => {
      if (val.length < 8) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Your password must be at least 8 characters long",
          fatal: true
        })

        return z.NEVER
      }

      passwordRequirements.map((req) => {
        if (!req.re.test(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: req.label
          })
        }
      })
    }),
    confirmPassword: z
      .string()
      .min(8, { message: "Your password must be at least 8 characters long" })
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"]
  })

export const changePasswordSchema = z
  .object({
    newPassword: z.string().superRefine((val, ctx) => {
      if (val.length < 8) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Your password must be at least 8 characters long",
          fatal: true
        })

        return z.NEVER
      }

      passwordRequirements.map((req) => {
        if (!req.re.test(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: req.label
          })
        }
      })
    }),
    confirmPassword: z
      .string()
      .min(8, { message: "Your password must be at least 8 characters long" })
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"]
  })
