import { Tuple } from "@mantine/core"

const generateShades = (baseColor: string): Tuple<string, 10> => {
  return [...Array(10)].map((e) => baseColor) as Tuple<string, 10>
}

const generateShadesMany = (
  colorOne: string,
  colorTwo: string
): Tuple<string, 10> => {
  return [...Array(5)]
    .map((e) => colorOne)
    .concat([...Array(5)].map((e) => colorTwo)) as Tuple<string, 10>
}

export const platformColors = {
  "bg/white": generateShades("#ffffff"),
  "bg/black": generateShades("#000000"),
  "bg/alert-info": generateShades("#dcedfb"),
  "bg/alert-success": generateShades("#c1f0dc"),
  "bg/alert-warning": generateShades("#feeecd"),
  "bg/alert-error": generateShades("#fcc8d1"),
  "bg/inverted-l1": generateShades("#222B3F"),
  "bg/inverted-l2": generateShades("#1a2132"),
  "bg/primary-l1": generateShades("#e2e4e7"),
  "bg/primary-l2": generateShades("#F4F5F5"),
  "bg/primary-l3": generateShades("#ffffff"),
  "bg/inverted-l1-ai": generateShades("#2D1E69"),
  "bg/inverted-l2-ai": generateShades("#1B1240"),
  "bg/tabs/default-active": generateShadesMany("#CAE4F9", "#395060"),
  "progress/blue-bg": generateShades("#DCEDFB"),
  "progress/blue-active": generateShades("#84C0F1"),
  "text/default-ai": generateShades("#2D1E69"),
  "text/lightgray": generateShades("#fcfcfc"),
  "text/white": generateShades("#ffffff"),
  "text/black": generateShades("#000000"),
  "text/default": generateShades("#1a2132"),
  "text/highlight-ai": generateShades("#6749DF"),
  "text/inverted-half-dimmed": generateShades("#e2e4e7"),
  "text/inverted-disabled": generateShades("#959AA5"),
  "text/half-dimmed": generateShades("#3b4358"),
  "text/inverted": generateShades("#ffffff"),
  "text/dimmed": generateShades("#6e7483"),
  "text/highlight": generateShadesMany("#EDF6FD", "#297abd"),
  "text/success": generateShades("#219C69"),
  "text/search-result": generateShades("#18486F"),
  "text/warning": generateShades("#CE8900"),
  "text/alert": generateShades("#297abd"),
  "text/disabled": generateShades("#b0b3bb"),
  "border/flow": generateShades("#565D6E"),
  "border/flow-hover": generateShades("#3B4358"),
  "border/card": generateShades("#E2E4E7"),
  "border/white": generateShades("#ffffff"),
  "border/black": generateShades("#000000"),
  "border/ai": generateShades("#9A84F1"),
  "border/input": generateShades("#EFEFF1"),
  "border/input-active": generateShades("#888d99"),
  "border/alert": generateShades("#c82d47"),
  "border/inverted-dimmed": generateShades("#1a2132"),
  "border/move": generateShades("#61AEED"),
  "button/default-ai": generateShades("#D3CAF9"),
  "button/ai-hover": generateShades("#D3CAF9"),
  "button/inverted": generateShades("#222B3F"),
  "button/primary": generateShades("#fcc75c"),
  "button/secondary": generateShades("#efeff1"),
  "button/secondary-hover": generateShades("#C8CAD0"),
  "primitive/yellow/50": generateShades("#fbb72e"),
  "primitive/neutral/950": generateShades("#171B26"),
  "primitive/neutral/50": generateShades("#C8CAD0"),
  "primitive/purple/0": generateShades("#F8F6FE"),
  "primitive/purple/5": generateShades("#F0EDFD"),
  "primitive/purple/50": generateShades("#7D61ED"),
  "primitive/purple/80": generateShades("#3a219d"),
  "primitive/blue/60": generateShades("#499cdf"),
  "chip/purple": generateShades("#F0EDFD"),
  "chip/blue": generateShades("#EDF6FD"),
  "chip/blue-hover": generateShades("#dcedfb"),
  "chip/blue-active": generateShades("#cae4f9"),
  "chip/yellow": generateShades("#FFF4DE"),
  "chip/yellow-hover": generateShades("#feeecd"),
  "chip/yellow-active": generateShades("#fde6b9"),
  "chip/green": generateShades("#eafaf3"),
  "chip/green-hover": generateShades("#c1f0dc"),
  "chip/green-active": generateShades("#99e5c6"),
  "chip/red": generateShades("#fee7eb"),
  "chip/red-hover": generateShades("#fddae0"),
  "chip/red-active": generateShades("#fcc8d1"),
  "skin/msteams": generateShades("#5b5fbf"),
  "skin/msteams-secondary": generateShades("#c0c1e5"),
  "skin/sms": generateShades("#4e80ee"),
  "skin/sms-secondary": generateShades("#d0d8e8"),
  "skin/whatsapp": generateShades("#219C69"),
  "skin/whatsapp-secondary": generateShades("#c5eeac")
} as const

export type ExtendedCustomColors = keyof typeof platformColors
declare module "@mantine/core" {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>
  }
}
